@import "variables";
@import "mixins";
@import "fonts";
@import "fixes/chat-fix";
@import "fixes/modal";
@import "fixes/invite-modal";
@import "fixes/call-view";
@import "fixes/mobile-call";
@import "fixes/date-picker";
@import "fixes/material-overrite";
@import "utils";
@import "margin-padding-classes";
@import "fixes/new-call";
@import "fixes/root-call";
@import "fixes/call-style";
@import "intl-tel-input/build/css/intlTelInput.css";
@import "fixes/dashboard-report";

$image-path: "~src/assets/images/icons/";
$image-new-path: "~src/assets/images/new-icons/";

html {
  font-size: $base-font-size;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
}

body {
  font-size: $default-font-size;
  margin: 0;
  padding: 0;
  min-height: calc(var(--app-height));
  background: $color-bg-gray !important;
  -webkit-print-color-adjust: exact !important;
  font-family: $default-font-family;
  font-weight: $default-font-weight;
  line-height: $default-line-height;
  color: $default-font-color;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}

.container-xl {
  @include for-desktop-xl {
    max-width: 100% !important;
  }
}

.common-icon {
  width: 22px;
  height: 22px;
  display: inline-flex;
  margin: 0 4px;
  background-size: contain;
}

.tile-head {
  position: inherit;
}

.pointer {
  cursor: pointer;
}

.green {
  color: $color-bg-green;
}
.blue {
  color: $color-prime-blue;
}
.yellow {
  color: $color-prime-yellow;
}

.wrap-text {
  max-width: 88px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @include for-desktop-min {
    max-width: 55px;
  }
}

/*-------- Slider ---------*/

.range-slider {
  &,
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min)) / var(--range));
  --sx: calc(0.5 *#{30px} + var(--ratio) * (100% - #{30px}));
  margin: 0;
  padding: 0;
  width: 100%;
  height: 30px;
  background: transparent;
  font: 1em/1 arial, sans-serif;
  -webkit-appearance: none;
  outline: none;

  &::-webkit-slider-runnable-track {
    @include track(1);
  }
  &::-moz-range-track {
    @include track;
  }
  &::-ms-track {
    @include track;
  }

  &::-moz-range-progress {
    @include fill;
  }
  &::-ms-fill-lower {
    @include fill;
  }

  &::-webkit-slider-thumb {
    margin-top: -12px;
    @include thumb;
  }
  &::-moz-range-thumb {
    @include thumb;
  }
  &::-ms-thumb {
    margin-top: 0;
    @include thumb;
  }

  &::-ms-tooltip {
    display: none;
  }
}
/*--------Chat  Styles ---------*/
.chat-window {
  max-width: 100vw !important;
  height: calc(100vh - 160px);
  width: 100vw;
  position: fixed !important;
  z-index: 500 !important;
  transform: none !important;
  top: 80px !important;
  margin-bottom: 0 !important;
  &.popover {
    border-radius: 0 !important;
    box-shadow: none !important;
    margin-top: 0;
    .popover-body {
      padding: 0;
    }
    .arrow {
      display: none;
    }
  }
  @include for-desktop {
    max-width: 440px !important;
    right: 0;
    left: unset !important;
    top: 60px !important;
    height: 600px !important;
    &.popover {
      border-radius: 8px !important;
      .arrow {
        left: 330px;
        display: block !important;
      }
    }
  }
  img {
    max-width: 16rem;
  }
  .msg-sent {
    background: url("#{$image-path}msg-sent.svg") right center no-repeat;
  }
  .msg-not-sent {
    background: url("#{$image-path}msg-not-sent.svg") right center no-repeat;
  }
  .msg-sending {
    background: url("#{$image-path}msg-sending.svg") right center no-repeat;
  }
}

/*--------Popover Styles ---------*/
.popover {
  border-radius: 8px;
  border: 1px solid $color-light;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.17);

  &.save-video-custom {
    @include fontStyle(
      $font-size-xxs,
      $font-weight-semi-bold,
      $color-text-light
    );
  }
  &.popover-bottom,
  &.bs-popover-bottom {
    .arrow {
      &::before {
        border-bottom-color: $color-light !important;
      }
    }
  }
  &.popover-left-bottom,
  &.bs-popover-left {
    .arrow {
      &::before {
        border-left-color: $color-light !important;
      }
    }
  }
}

.alert-popover {
  max-width: 320px !important;
}

.aud-vid-config {
  font-size: $font-size-sm !important;
  color: $color-text-light;
  max-width: 330px !important;
  .arrow {
    display: none !important;
  }
  .popover-body {
    padding: 16px;
  }
  span {
    font-size: $font-size-xxs;
    color: $color-text-gray;
  }
  i {
    width: 20px;
    height: 20px;
    background: url("#{$image-path}device-sel-icon.svg") center center no-repeat;
  }
}

.tag-popover {
  span {
    font-size: $font-size-xs;
    width: fit-content;
  }
}

/*--------popover list Styles ---------*/
.select-list {
  padding: 10px;
  @include fontStyle($font-size-xxs, $font-weight-normal, $color-text-gray);
  .list-item {
    @include fontStyle($font-size-sm, $font-weight-normal, $color-text-light);
    &.selected {
      color: $color-prime-blue;
    }
  }
}

.pop-header {
  @include fontStyle($font-size-xxs, $font-weight-normal, $color-text-gray);
}

/*--------Drag and Drop Styles ---------*/
.schedule-box {
  border-radius: 14px;
  border: 1px solid $color-bg-gray;
  background-color: $color-light;
  width: 152px;
  margin: 0 auto;
  @include for-desktop-min {
    width: 100%;
  }
  &__head {
    padding: 16px 10px;
    border-radius: 14px 14px 0 0;
    background-color: $color-bg-gray;
    @include for-desktop-min {
      padding: 16px 6px;
      font-size: $font-size-xs;
    }
    @include fontStyle($font-size-sm, $font-weight-bold, $color-text-light);
    i {
      &.trainer {
        background: url("#{$image-path}trainer.svg") center center no-repeat;
      }
      &.sale {
        background: url("#{$image-path}learning-green.svg") center center
          no-repeat;
      }
      &.regular {
        background: url("#{$image-path}normal-shift-icon.svg") center center
          no-repeat;
      }
      @include for-desktop-min {
        width: 20px;
      }
    }
    .more {
      padding: 5px;
      border-radius: 8px;
      width: 34px;
      height: 34px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05),
        -2px -2px 4px rgba(255, 255, 255, 0.25);
      background: $color-prime-white url("#{$image-path}three-dot.svg") center
        center no-repeat;
      @include for-desktop-min {
        width: 18px;
        height: 18px;
        border-radius: 4px;
        background-size: 70%;
      }
    }
  }
  &__body {
    padding: 16px 12px;
    @include for-desktop-min {
      font-size: $font-size-xs;
    }
    @include fontStyle($font-size-sm, $font-weight-normal, $color-text-light);
    .user-image {
      width: 30px;
      height: 30px;
      margin: 0 10px 0 0;
      border-radius: 4px;
      background: url("#{$image-path}profile.svg") center center no-repeat;
      @include for-desktop-min {
        background-size: 92%;
      }
    }
    .time-card {
      border-radius: 14px;
      padding: 12px 10px;
      @include fontStyle($font-size-xs, $font-weight-normal, $color-text-light);
      @include for-desktop-min {
        border-radius: 8px;
        padding: 6px 4px;
        font-size: $font-size-xxs;
        .arrow-right {
          background-size: 100% !important;
          width: 10px;
        }
      }
      border: 1px solid $color-border-gray;
      .arrow-right {
        background: url("#{$image-path}arrow.svg") center center no-repeat;
      }
    }
  }
}
.more {
  padding: 5px;
  border-radius: 8px;
  width: 34px;
  height: 34px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05),
    -2px -2px 4px rgba(255, 255, 255, 0.25);
  background: $color-prime-white url("#{$image-path}three-dot.svg") center
    center no-repeat;
}
.list-item {
  background-color: $color-light;
  @include fontStyle($font-size-sm, $font-weight-normal, $color-text-light);
  .user-image {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background: url("#{$image-path}profile.svg") center center no-repeat;
  }
  .info {
    width: 24px;
    height: 24px;
    background: url("#{$image-new-path}info.svg") center center no-repeat;
  }
  .client-name {
    color: $color-text-gray;
  }
  .clint-logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $color-dark;
  }
}

// Close modal

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  &:hover,
  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
}

app-image-expand-modal {
  height: calc(100vh - 29px);
  @include for-mobile-md {
    height: calc(100vh - 2px);
  }
}
mention-list {
  .mention-active {
    background: $color-blue;
  }
}

app-survey-modal {
  min-height: calc(100vh - 2px);
}

.CoachingMotivationModalClass {
  .modal-dialog {
    max-height: calc(100vh - 35px);
  }
}

app-coaching-motivation-modal,
app-assign-agent-list-modal {
  input[type="radio"]:not(:checked),
  input[type="radio"]:checked {
    transform: scale(1.2);
    width: 16px;
    height: 16px;
    -webkit-appearance: none;
    border-radius: 50%;
    outline: none;
    border: 1.8px solid $color-dark-gray;
  }
  input[type="radio"]:checked {
    background: url("#{$image-path}selected-radio-button.svg") center center
      no-repeat;
    border: 1.8px solid $color-prime-blue;
  }
}

app-assign-agent-list-mobile {
  input[type="radio"]:not(:checked),
  input[type="radio"]:checked {
    transform: scale(1.2);
    width: 22px;
    height: 22px;
    -webkit-appearance: none;
    border-radius: 50%;
    outline: none;
    border: 1.8px solid $color-board-count;
  }
  input[type="radio"]:checked {
    background: url("#{$image-path}selected-radio-button.svg") center center
      no-repeat;
    border: 4.2px solid $color-prime-blue;
  }
}

app-survey {
  .sv-mainroot {
    min-width: 0 !important;
  }
}

app-request-appointment {
  ngb-datepicker.dropdown-menu {
    font-size: 16px;
    top: -20px;
    .ngb-dp-header {
      height: 4rem;
      ngb-datepicker-navigation-select > .custom-select {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 0 0.5rem;
        font-size: 1.6rem;
        height: 3rem;
        width: 7rem;
      }
    }
    .ngb-dp-day {
      width: 3rem;
      height: 3rem;
      @include for-mobile-md {
        width: 3.7rem;
      }
    }
    .ngb-dp-day > div {
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      @include for-mobile-md {
        width: 3.7rem;
      }
    }
    .ngb-dp-weekday {
      width: 3rem;
      @include for-mobile-md {
        width: 3.7rem;
      }
    }
  }
}
app-reports {
  .dx-datagrid-header-panel::before {
    content: "Overview";
    @include fontStyle($font-size-sm, $font-weight-normal, $color-dark-gray);
  }
  .dx-datagrid-header-panel {
    display: flex;
    .dx-toolbar {
      justify-content: flex-end;
      padding: 0 1rem;
    }
  }
  td {
    text-align: center !important;
  }
}

app-participants-information {
  .popover-body {
    max-width: 84px;
    padding: 0.5rem 0.4rem;
  }
}

app-request-chat-input {
  .dropdown-menu {
    width: 100%;
  }
}

.op-popover {
  position: absolute;
  padding-bottom: 8px;
  top: -80px;
  left: 42px;
  width: 300px;
  z-index: 2;
  color: $color-text-light;
  &-arrow {
    position: absolute;
    border: solid $color-light;
    border-width: 6px 0 0 6px;
    display: inline-block;
    width: 12px;
    height: 12px;
    left: 82px;
    bottom: -4px;
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    &.for-cam {
      left: 134px;
    }
  }
  &-body {
    background-color: $color-light;
    border-radius: $border-radius-medium;
    font-size: $font-size-xs;
    padding: 10px 20px;
    filter: drop-shadow(0px 10px 24px rgba(0, 0, 0, 0.24));
    position: relative;
    button {
      height: auto;
      padding: 2px 16px;
      width: 80px;
    }
  }
}
.invite-link-custom {
  width: 400px !important;
  max-width: 400px !important;
  .popover-body {
    padding: 0;
  }
}

app-video-call,
app-in-call-desktop {
  .video__content {
    .reload {
      width: 100%;
      position: absolute;
      z-index: 1;
      top: 96px;
      background-color: $color-crimson-red;
      padding: 12px 16px;
      color: $color-prime-white;
      font-size: $font-size-xxs;
      font-weight: 600;
      button {
        font-size: $font-size-xxs;
        margin-left: 8px;
        line-height: 16px;
        padding: 4px 16px;
        border: 1px solid #fafafa;
        box-sizing: border-box;
        border-radius: 12px;
        color: $color-prime-white;
      }
    }
  }
  .participant[data-muted]::after,
  .local-track.video-mic-off::after {
    color: $color-light;
    font-size: $font-size-xxs;
    font-weight: $font-weight-bolder;
    background: rgba(0, 0, 0, 0.75) url("#{$image-path}video-mic-off.svg") 5px
      center no-repeat;
    padding: 5px 10px 5px 30px;
    position: absolute;
    height: 25px;
    z-index: 1;
    left: 8px;
    bottom: 30px;
    content: "";
    width: auto;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;
  }
  .participant[data-muted]::after {
    content: attr(data-muted) !important;
    border-radius: 4px;
  }
  .local-track.video-mic-off::after {
    bottom: 0;
    border-top-right-radius: 4px;
    left: 0;
    padding-right: 0;
  }
  .co-browse,
  #agentShare {
    width: 100%;
    background: $color-cobrowse-gray;
    &.browse-max {
      width: calc(100vw - 240px);
    }
  }
  .minimize {
    .participant[data-muted]::after {
      bottom: 22px;
      content: "" !important;
      padding-left: 16px;
      height: 22px;
    }
  }
  .minimize,
  .maximize {
    .participant-container {
      .video-previous,
      .video-next {
        position: absolute;
        z-index: 1;
        top: 22px;
        transform: rotate(90deg);
        background: $color-dark-gray;
        box-shadow: none;
        border: none;
        width: 75px;
        cursor: pointer;
        outline: none;
        i {
          border: solid $color-light;
          border-width: 0 2px 2px 0;
          display: inline-block;
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
        &:disabled {
          opacity: 0.5;
        }
      }
      .video-previous {
        left: -27px;
        padding: 2px 6px 6px 10px;
        top: 27px;
      }
      .video-next {
        right: -27px;
        padding: 5px 6px 0 6px;
        top: 27px;
        i {
          transform: rotate(225deg);
          -webkit-transform: rotate(225deg);
        }
      }
    }
  }
  .maximize {
    .participant-container {
      #participant {
        flex-direction: column;
      }
      .video-previous,
      .video-next {
        transform: rotate(180deg);
        background-color: $color-dark;
      }
      .video-next {
        top: inherit;
        width: 100%;
        left: 0;
        bottom: 0;
      }
      .video-previous {
        top: 0;
        width: 100%;
        left: 0;
      }
    }
  }
}

.white-box {
  background-color: $color-light;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 8px;
  height: 100%;
}

app-active-outreach {
  .tooltip {
    opacity: 1 !important;
    .arrow::before {
      border-right-color: $color-light;
      border-left-color: $color-light;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
    }
    .tooltip-inner {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
      background-color: $color-light;
      @include fontStyle($font-size-xxs, $font-weight-bold, $color-cobalt);
    }
  }
}

/*--------Date Picker Styles Starts ---------*/

.date-picker {
  .ngb-dp-month,
  .ngb-dp-months {
    width: 100%;
  }
  .ngb-dp-weekdays,
  .ngb-dp-header {
    background: transparent;
    border-bottom: none;
    ngb-datepicker-navigation {
      width: 200px;
      margin: 0 auto;
      .ngb-dp-arrow-btn {
        outline: none;
        box-shadow: none;
        .ngb-dp-navigation-chevron {
          color: $color-text-light;
        }
        &.hide {
          display: none;
        }
      }
    }
  }
  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday {
    width: calc(100% / 7);
    height: calc((100vh - 398px) / 6);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-text-gray;
    font-style: initial;
    font-size: 12px;
    margin: 2px;
    &.hidden {
      visibility: hidden;
    }
    .day {
      width: 100%;
      height: 100%;
      background-color: $color-bg-gray;
      border: 1px solid $color-border-gray;
      border-radius: 4px;
      color: $color-text-light;
      &.day-with-slots {
        background-color: $color-light;
      }
      &.exceptions-with-slots {
        border: 2px solid $color-prime-red;
        border-radius: 4px;
        position: relative;
        &::after {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 0;
          right: 0;
          content: "";
          background: $color-prime-red url("#{$image-path}edit-white.svg")
            center center no-repeat;
        }
      }
      &.past {
        opacity: 0.5;
      }
    }
  }
  .ngb-dp-weekday {
    border: none;
  }
  .ngb-dp-today {
    .date {
      background-color: $color-text-light;
      color: $color-light;
      padding: 2px 5px;
      border-radius: 4px;
    }
  }
  .small-datePicker {
    ngb-datepicker {
      box-shadow: 0 6px 16px rgba(0, 0, 0, 0.24);
      background-color: $color-light;
      border-radius: 16px;
      border: none;
      padding: 10px;
    }
    .day.exceptions-with-slots::after {
      display: none;
    }
    .ngb-dp-day,
    .ngb-dp-week-number,
    .ngb-dp-weekday {
      width: 25px;
      height: 25px;
    }
    .ngb-dp-today {
      .date {
        font-size: 8px;
        padding: 1px 2px;
      }
    }
  }
}

inbox {
  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border: none;
    border-bottom: 1px solid $color-border-gray;
  }
  .ql-toolbar.ql-snow {
    border-top: 1px solid $color-border-gray;
    background-color: $color-light-gray;
  }
  .ql-container.ql-snow {
    max-height: 60px;
    overflow-y: auto;
    .ql-editor {
      padding: 20px 15px;
      img {
        height: 50px;
      }
    }
  }
}

/*--------Date Picker Styles Ends ---------*/

.message pre p {
  margin-bottom: 0;
  white-space: initial;
}

app-outbound-drawer {
  ngb-accordion {
    .accordion-item {
      border: none;
      border-bottom: 1px solid $color-cobalt !important;
      .accordion-body {
        padding: 12px 12px 12px 28px;
      }
    }
  }
}

app-active-call {
  width: 100%;
  .active-call {
    app-active-call-chat {
      height: calc(100% - 56px);
      width: 100%;
      display: flex;
      align-items: flex-end;
    }
    app-participants-info {
      height: 100%;
      background-color: $color-light;
      border-radius: 0 8px 8px 0;
    }
    &.in-small-room {
      app-active-call-chat {
        //commenting as part of hiding switch room (small and main)
        //padding-top: 48px;
      }
    }
  }
}

app-history {
  ngx-spinner {
    .overlay {
      top: 140px !important;
    }
  }
}

app-chat-message,
app-active-call-chat {
  mat-spinner {
    height: 30px !important;
  }
}

/*---------temporary fix for all other routes in new portal-------*/

//TODO:remove after actual implementation completed

.desktop-body,
.mobile-body {
  height: 100%;
  position: relative;
  .user-activity-container,
  .agent__list,
  .reports-container,
  .history-container {
    background: $color-light;
    padding: 20px;
    border-radius: 8px;
    margin: 0 !important;
  }
  .user-activity-container {
    .show-drawer {
      top: 24px;
    }
  }
  .settings-container {
    .side-panel {
      display: none;
    }
    .main-panel {
      padding: 0 !important;
    }
  }
}

$image-path-flag: "https://dx9457ojp328v.cloudfront.net/images/";

app-intel-tell-input {
  .iti {
    width: 100%;
  }
  .iti__country-list {
    max-width: 164px;
  }
}

app-customer-reviews {
  ngb-progressbar {
    &.progress {
      background-color: $color-vanishing-blue;
      border-radius: 8px;
      height: 8px;
    }
    .progress-bar {
      background-color: $color-gold-yellow;
      height: 8px;
    }
  }
}

app-executive-dashboard {
  .leads {
    ngb-progressbar {
      margin-bottom: 4px;
      &.progress {
        border-radius: 8px;
        height: 5px;
        background-color: transparent;
      }
      .progress-bar {
        height: 5px;
        border-radius: 8px;
      }
    }
  }
}

.history-container {
  padding: 0 !important;
  background: revert !important;
  .table {
    .historyList {
      max-height: 500px;
    }
    thead {
      position: sticky;
      top: -16px;
      z-index: 1;
      background: $color-board-bg;
      max-height: calc(100% - 10em);
      table-layout: fixed;
      th {
        border: none;
        font-size: 13px;
        color: $color-board-count;
        font-weight: $font-weight-semi-bold;
        position: sticky;
        z-index: 1;

        &.history-head {
          width: 45px;
        }
      }
    }

    tbody {
      font-size: 13px;
      color: $color-dark-medium;
      tr {
        background: $color-light;
        border-radius: 8px;
        &.no-background {
          background: unset;
          line-height: 8px;
          td {
            padding: 0;
          }
        }
        &.call {
          &-details {
            border-radius: 8px 8px 0 0;
          }
          &-more {
            border-radius: 0 0 8px 8px;
          }
        }
      }
      td {
        padding: 10px 8px;
        border: none;
        vertical-align: middle;
      }
    }
  }
  tbody {
    display: block;
    max-height: calc(100vh - 355px);
    overflow: auto;

    tr td {
      .sms {
        svg path {
          fill: $color-dark-green;
        }
      }

      .fbmessenger {
        svg path {
          fill: $color-active-blue-select;
        }
      }
    }
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  thead {
    width: 98%;
  }
  ngb-pagination {
    .pagination {
      justify-content: flex-end;
      margin: 8px 0;
      li {
        .page-link {
          padding: 9px 12px;
          color: $color-active-blue;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
        }
        &.disabled {
          .page-link {
            color: $color-text-gray;
          }
        }
      }
      .page-item.active .page-link {
        color: $color-light;
        background: $color-active-blue;
        border-color: $color-active-blue;
      }
    }
  }
}

app-range-date-picker {
  .range-date-picker {
    ngb-datepicker {
      width: 375px;
      height: 285px;
      .ngb-dp-day .btn-light {
        background: $color-light;
      }
      .ngb-dp-day.disabled {
        color: $color-text-gray;
      }
      .ngb-dp-arrow-btn {
        outline: none;
      }
      .ngb-dp-weekdays .ngb-dp-weekday {
        color: $color-active-blue !important;
      }
    }
    .custom-day {
      text-align: center;
      padding: 0.185rem 0.25rem;
      display: inline-block;
      height: 2rem;
      width: 2rem;
    }
    .custom-day.focused {
      background-color: $color-active-blue;
    }
    .custom-day.range,
    .custom-day:hover {
      background-color: $color-active-blue;
      color: white;
    }
    .custom-day.faded {
      opacity: 0.5;
    }
  }
}

.common-agent-avatar {
  width: 28px;
  height: 28px;
  background-color: $color-prime-red;
  color: $color-light;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

/*-------404 page Styles ---------*/
.page-not-found {
  .message {
    h1,
    p {
      span,
      a {
        color: $color-prime-orange;
        text-decoration: none;
      }
    }
  }
}

/*-------settings page Styles ---------*/
.notification-type {
  width: 300px;
  @media screen and (max-width: 500px) {
    margin-bottom: 20px;
  }
}

app-delonghi-executive-dashboard {
  [hideXAxisTicks] {
    [ngx-charts-x-axis-ticks] {
      > g {
        ~ g {
          display: none;
        }
      }
    }
  }
  ngb-rating {
    .visually-hidden {
      display: none;
    }
  }
}
.settings-container {
  &.in-mobile {
    .mobile-menu {
      .mat-button-toggle-group {
        border-color: transparent;
        .mat-button-toggle {
          border-color: transparent;
          &.mat-button-toggle-checked {
            background-color: transparent;
            border-bottom: 4px solid $color-active-blue;
            outline: none;
            color: $color-active-blue;
          }
          .mat-button-toggle-button {
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    .inbound-container {
      max-height: calc(100vh - 285px);
    }
    .booking-hours {
      .config-container {
        max-height: calc(100vh - 285px) !important;
      }
    }
  }
}

.ngb-tp-input-container {
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}

.queue-board {
  &.mobile-view {
    app-queue-card {
      .queue-card {
        .card-content {
          max-width: 100%;
        }
      }
    }
  }
}

.nurture-form {
  app-intel-tell-input {
    input {
      height: 56px;
      width: 100%;
      border: 2px solid $color-light-grey;
      &:focus {
        outline: none;
        border: 2px solid #3f51b5;
      }
    }
  }
  button {
    .mdc-button__label {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}
